var Constants = {
    MOMENT_DATE_STANDARD_FORMAT: 'YYYY-MM-DD',
    MOMENT_DATE_UNDERSCORE_YYYY_MM_DD: 'YYYY-MM-DD',
    MOMENT_DATE_HYPHEN_YYYY_MM_DD: 'YYYY-MM-DD',
    MOMENT_DATE_SYSTEM_FORMAT: 'DD/MM/YY',
    MOMENT_FORMAT_SLASH_DD_MM_YYYY: 'DD/MM/YYYY',
    MOMENT_FORMAT_SLASH_DD_MM_YY: 'DD/MM/YY',
    DATE_FORMAT_DATE_PICKER: 'dd/mm/yy',
    DATE_FORMAT_DATE_TIME_PICKER: 'dd/mm/yy hh:ii',

    MOMENT_DATE_TIME_SYSTEM_FORMAT: 'DD/MM/YY hh:ii',
    MOMENT_DATE_UNDERSCORE_YYYY_DD_MM: 'YYYY-DD-MM',
};
