//self contains function
var NavBarCtrl = (function($, NavBarService) {
    'use strict';

    var init = function(){
        initLanguage();
        //registerLanguageChangeClick();
    };

    var initLanguage = function(){
        var language = getLanguage();
        if(!language || utils.object.isUndefined(language)) language = 'en';
        highLightCurrentLanguage(language);
    }

    var language_key = 'goshu_language_key'
    var saveLanguage = function(language){
        localStorage.setItem(language_key, language);
    }

    var getLanguage = function(){
        return localStorage.getItem(language_key);
    }

    var highLightCurrentLanguage = function(language){
        //$('.nav-lang').removeClass('selected');
        var linkLanguageId = String.format('.nav-lang-{0}', language);
        $(linkLanguageId).addClass('selected');
    }

    var registerLanguageChangeClick = function(){
        $('.nav-lang').click(function(e){
            var language = $(this).data('language');
            //submit form
            var formId = String.format('#form-language-{0}', language);
            saveLanguage(language);
            $(formId).submit();
        });
    }

    var changeLanguage = function(language){
        //var language = $(this).data('language');
        //submit form
        var formId = String.format('#form-language-{0}', language);
        saveLanguage(language);
        $(formId).submit();
    };

    $(document).ready(function(){
        init();
    });

    return {
        changeLanguage: changeLanguage
    };

})(jQuery, NavBarService);