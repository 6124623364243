//self contains function
var AuthorizationService = (function($) {
    'use strict';

    var getFeaturePermission = function(user, featureSlug){
        var param ={user: user, feature: featureSlug};
        return $.post( config.api.action.authorization.getFeaturePermission, param);
    }

    return {
        getFeaturePermission: getFeaturePermission
    };

})(jQuery);
