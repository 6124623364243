//self contains function
var SideBarCtrl = (function($, SideBarService) {
    'use strict';

    var init = function(){
        //initLanguage();
        //registerLanguageChangeClick();
    };

    var toggleSideBar = function(){
        var body = $('body');
        var toggleIcon = $('.icon-arrow-left-circle');
        if(body.hasClass('mini-sidebar')) {
            body.removeClass('mini-sidebar');
            toggleIcon.removeClass('ti-menu');
        } else {
            body.addClass('mini-sidebar');
            toggleIcon.addClass('ti-menu');
        }
    }

    $(document).ready(function(){
        init();
    });

    return {
        toggleSideBar: toggleSideBar
    };

})(jQuery, SideBarService);