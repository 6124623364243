var utils = {
    object: {
        isUndefined: function isUndefined(obj) {
            if (typeof (obj) === 'undefined')
                return true;
            return false;
        },

        isNullOrUndefined: function(obj){
            if (typeof (obj) === 'undefined')
                return true;
            if(obj === null) return true;
            return false;
        },

        isNullOrEmpty: function(obj){
            if (typeof (obj) === 'undefined')
                return true;
            if(obj === null) return true;
            if(obj.length === 0) return true;
            return false;
        }
    }

};

utils.date = {
    convertToSystemDate : function(strDate, format){
        if(utils.object.isNullOrUndefined(strDate)) return '';

        var dt = moment(strDate, format);
        dt = dt.format(Constants.MOMENT_DATE_SYSTEM_FORMAT);
        return dt;
    },
    isAfter:function(strDate1, strDate2, momentFormat){
        var dt1 = moment(strDate1, momentFormat);
        var dt2 = moment(strDate2, momentFormat);

        dt1 = dt1.format(Constants.MOMENT_DATE_STANDARD_FORMAT);
        dt2 = dt2.format(Constants.MOMENT_DATE_STANDARD_FORMAT);
        return dt1 > dt2;
    },
    convertToDDMMYYYY: function(strDate, format){
        if(utils.object.isNullOrUndefined(strDate) || utils.object.isNullOrEmpty(strDate)) return '';

        var dt = moment(strDate, format);
        dt = dt.format(Constants.MOMENT_FORMAT_SLASH_DD_MM_YYYY);
        return dt;
    },
    convertToDDMMYY: function(strDate, format){
        if(utils.object.isNullOrUndefined(strDate) || utils.object.isNullOrEmpty(strDate)) return '';

        var dt = moment(strDate, format);
        dt = dt.format(Constants.MOMENT_FORMAT_SLASH_DD_MM_YY);
        return dt;
    }
}

utils.alert = {
    showSuccess: function(message){
        toastr.options.positionClass = "toast-top-center";
        toastr.success(message);
    },
    showError: function(message, title){
        toastr.options = {
            "closeButton": true,
            "debug": false,
            "newestOnTop": false,
            "progressBar": false,
            "positionClass": "toast-top-center",
            "preventDuplicates": false,
            "onclick": null,
            "showDuration": 300,
            "hideDuration": 1000,
            "timeOut": 50000,
            "extendedTimeOut": 1000,
            "showEasing": "swing",
            "hideEasing": "linear",
            "showMethod": "fadeIn",
            "hideMethod": "fadeOut"
        }
        toastr.error(message, title);
    }
};

utils.http = {
    getUrlParams: function getUrlParams( prop ) {
        var params = {};
        var search = decodeURIComponent(window.location.href.slice(window.location.href.indexOf('?') + 1));
        var definitions = search.split('&');

        definitions.forEach(function (val, key) {
            var parts = val.split('=', 2);
            params[parts[0]] = parts[1];
        });

        return (prop && prop in params) ? params[prop] : '';
    }
}


