var LoaderCtrl = (function(){
    /*
    * container: dom id or class
    * options: {}
    *
    * */
    var showLoader = function(selector, options){

        if(options && !utils.object.isUndefined(options)){
            options.maxSize = 40;
            options.minSize = 40;
            options.size = 60;
            $(selector).LoadingOverlay("show", options);
        }
        else {
            options = {};
            options.maxSize = 40;
            options.minSize = 40;
            options.size = 60;
            $(selector).LoadingOverlay("show");
        }

    }
    var removeLoader = function(selector){
        $(selector).LoadingOverlay('hide');
    }

    return {
        showLoader:showLoader,
        removeLoader: removeLoader
    }
})();