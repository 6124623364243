var config = (function () {
    var api = {
        url: '/api/'
    };

    api.action = {
        order: {
            production_management: {
                create_new: api.url + 'order/production_management/add',
                get_list: api.url + 'order/production_management/get_list',
                update_production: api.url + 'order/production_management/update_production',
                factory_update_production: api.url + 'order/production_management/factory_update_production',
                delete_production: api.url + 'order/production_management/delete_production',
                filter_production: api.url + 'order/production_management/filter_production',
                get_production_by_order: api.url + 'order/production_management/get_order_ids',
                get_production_by_orderId: api.url + 'order/production_management/get_order_id',
                update_open_date: api.url + 'order/production_management/update_open_date'
            },
            pdf: {
                view_pdf: '/order/detail/pdf',
                production_tag_pdf:'/order/production_tag/pdf',
            }
        },
        authorization: {
            getFeaturePermission: api.url + 'authorization/get_feature_permission'
        },
        report: {
            order:{
                zone: api.url + 'report/order/zone',
                agent: api.url + 'report/order/agent',
                customer: api.url + 'report/order/customer',
            },
            order_line:{
                zone: api.url + 'report/order-line/zone',
                agent: api.url + 'report/order-line/agent',
                customer: api.url + 'report/order-line/customer',
            },
            exchange:{
                rate: api.url + 'report/order/exchange',
                rate_zone: api.url + 'report/order/exchange_zone',
            },
            master:{
                zone:api.url + '/report/master/zones',
                agent:api.url + 'report/order/get_agents',
                agent_zone: api.url + 'report/order/get_agents_by_zone'
            },
            moq:{
                surcharge: api.url + 'report/order/surcharge',
                surcharge_agent: api.url + 'report/order/surcharge_agent',
                surcharge_customer: api.url + 'report/order/surcharge_customer'
            },
            extra:{
                product: api.url + 'report/order/extra_product',

            }
        }
    }

    return {
        api:api
    }
})();
